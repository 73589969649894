import React, { useState } from 'react'
import { Chip, Stack, Grid, Paper, ButtonBase, Typography, Box } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


export default function EditCard({ title, subtitle, isComplited, renderAvatarComponent, onClick }) {
  const [isEdit, setIsEdit] = useState(false)

  const handleClick = () => {
    setIsEdit(!isEdit)
    onClick(!isEdit)
  }

  return (
    <ButtonBase onClick={handleClick}>
      <Box sx={{ width: '100%' }}>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            borderRadius: 6,
            boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.1)',
            borderWidth: 0.5,
            borderColor: '#ccc',
            borderStyle: 'solid'
          }
          }>
          <Box sx={{ width: '100%', textAlign: 'right' }}>
            <Chip
              icon={isComplited ? <CheckCircleIcon htmlColor="#17CC11" color='inherit' /> : <ErrorIcon htmlColor="#F13B13" color='inherit' />}
              label={<Typography sx={{ fontWeight: 'bold' }} variant="body2">{isComplited ? "Complete" : "Incomplete"}</Typography>}
              sx={{ color: isComplited ? "#17CC11" : "#F13B13", backgroundColor: isComplited ? "#AAFFA8" : "#FFCDC2" }}
              size="small"
            />
          </Box>

          <Grid container spacing={2} >
            <Grid item sx={{ display: 'flex' }}>
              {renderAvatarComponent()}
            </Grid>

            <Grid item xs>
              <Stack direction="column" alignItems="flex-start" spacing={0}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{title}</Typography>
                <Typography variant="subtitle1">{subtitle}</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </ButtonBase>
  )
}