import React from 'react'
import { Dialog, DialogActions, Button, DialogContent, Box, CircularProgress } from '@mui/material';
import Congratulations from './Congratulations';

export default function CongratulationsModal({open, complited, onClose}) {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
    >
      <DialogActions>
        {complited &&
          <Button onClick={onClose}>Close</Button>
        }
      </DialogActions>
      <DialogContent>

        {!complited &&
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: 500
            }}
          >
            <CircularProgress />
          </Box>
        }

        {complited &&
          <Congratulations />
        }

      </DialogContent>
    </Dialog>
  )
}