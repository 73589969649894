import { createTheme } from '@mui/material/styles';

export const GuestsType = {
  Anyone: "anyone",
  KidsOnly: "kids_only",
  AdultsRequired: "adults_required",
  AdultsOptional: "adults_optional",
  AdultsOnly: "adults_only"
}

export const DownloadFromStore = {
  iosURL: "https://apps.apple.com/us/app/huddle-playdate-planner/id6444031447",
  androidURL: "https://play.google.com/store/apps/details?id=io.codecatalyst.huddle"
}

export const VitalInfoAllergiesChipsTheme = createTheme({
  palette: {
      primary: {
          main: '#FE3930',
          contrastText: '#fff'
      },
      secondary: {
          main: '#FE9500',
          contrastText: '#fff'
      }
  },
});