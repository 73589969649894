import React, { useState } from 'react'
import dateFormat from "dateformat";
import { Typography, Grid, Paper, ToggleButtonGroup, ToggleButton, Box, Stack } from '@mui/material';
import { lightBlue } from '@mui/material/colors';

export default function DateCard({ dateObject: { date, duration }, selected, isMaybe = true, handleButtonSelection }) {
  const [showMaybe] = useState(isMaybe)
  const start = new Date(Date.parse(date))
  const end = new Date(Date.parse(date) + duration * 1000)
  const day = dateFormat(start, "d")
  const dayName = dateFormat(start, "ddd")
  const fullDate = dateFormat(start, "ddd, mmm dS")
  const timeRange = `${dateFormat(start, "h:MM")}-${dateFormat(end, "h:MM TT")}`

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        borderRadius: 6,
        boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.1)',
        borderWidth: 0.5,
        borderColor: '#ccc',
        borderStyle: 'solid'
      }
      }>
      <Grid container justifyContent="center" spacing={2} >
        <Grid item sx={{ display: 'flex', ml: 0 }}>
          <ColoredSection day={day} dayName={dayName} />
        </Grid>
        <Grid item xs>
          <Grid container direction="column" spacing={1} sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
            <Grid item>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{fullDate}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">{timeRange}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            orientation="vertical"
            exclusive
            value={selected}
            onChange={handleButtonSelection}
            aria-label="RSVP"
            sx={{ width: '70px' }}

          >
            <ToggleButton sx={{ height: showMaybe ? '35px' : '53px' }} value='yes' aria-label="yes" color='success'>Yes</ToggleButton>
            {showMaybe &&
              <ToggleButton sx={{ height: '35px' }} value='maybe' aria-label="maybe" color='info'>Maybe</ToggleButton>
            }
            <ToggleButton sx={{ height: showMaybe ? '35px' : '53px' }} value='no' aria-label="no" color='error'>No</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </Paper>
  );
}

function ColoredSection({ day, dayName }) {
  return (
    <Box sx={{ borderRadius: '16px', backgroundColor: lightBlue[400], p: 0, px: 3, textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
      <Stack spacing={0}>
        <Typography variant="h6" sx={{ mb: 0, mt: 0, color: 'white' }}>{dayName}</Typography>
        <Typography variant="h5" sx={{ mb: 0, mt: 0, color: 'white', fontWeight: 'bold' }}>{day}</Typography>
      </Stack>
    </Box>
  );
}