import React from 'react'
import { Box, Stack } from '@mui/material';
import DateCard from './DateCard';

export default function DateCards({ datesSelection, handleDatesSelectionChange }) {
  return (
    <Box sx={{ width: '100%', py: 2 }}>
      <Stack spacing={3}>
        {Object.entries(datesSelection).map(([key, date]) => (
          <DateCard
            key={date.event_date_id}
            dateObject={{ date: date.start, duration: date.duration }}
            selected={date.result}
            handleButtonSelection={(event, value) => {
              handleDatesSelectionChange(value, date.event_date_id)
            }}
          />
        ))}
      </Stack>
    </Box>
  )
}
