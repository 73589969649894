import React from 'react'
import { Stack, Typography, Box } from '@mui/material';
import Lottie from "lottie-react"
import SuccessConfettiAnimation from "../../lottie/success-confetti.json"
import AppleLogo from '../../images/download-app-store.png'
import GoogleLogo from '../../images/download-google-play.png'
import { DownloadFromStore } from '../../constants';

import { logClick } from '../../amplitude/amplitude.config';

export default function Congratulations() {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
  const isAndroid = /Android/i.test(navigator.userAgent) || navigator.userAgent.toLowerCase().indexOf('android') > -1

  const handleApple = () => {
    logClick('Open Apple store', 'Congratulations')
    window.location.href = DownloadFromStore.iosURL
  }

  const handleAndroid = () => {
    logClick('Open Android store', 'Congratulations')
    window.location.href = DownloadFromStore.androidURL
  }

  return (
    <>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >

        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Congratulations!</Typography>

        <Typography variant="body1">You're done on your end. Next, the host will finalize the date and time. Download the mobile app to get playdate notifications for agendas or moments.</Typography>

        <div style={{ width: "128px", height: "128px" }}>
          <Lottie animationData={SuccessConfettiAnimation} loop={true} style={{ width: "100%", height: "100%" }} />
        </div>

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          {!isAndroid &&
          <Box
            component="img"
            src={AppleLogo}
            onClick={handleApple}
            sx={{ width: '200px', height: 'auto'}}
          />
        }

        {!isIOS &&
          <Box
            component="img"
            src={GoogleLogo}
            onClick={handleAndroid}
            sx={{ width: '200px', height: 'auto' }}
          />
        }

        </Stack>


      </Stack>
    </>
  )
}
