import React from "react"
import { Box, Typography, Paper, Stack, Avatar } from '@mui/material'
import { Image } from 'mui-image';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import ParticipantCardChips from '../../invitation/ParticipantCardChips';
import { lightBlue, grey} from '@mui/material/colors';

export default function ProfilePassCard({ profilePass }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Box sx={{ width: '100%' }}>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            borderRadius: 6,
            boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.1)',
            borderWidth: 0.5,
            borderColor: '#ccc',
            borderStyle: 'solid'
          }
          }>

          <Stack spacing={2}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              {profilePass.image_url &&
                <Image
                  src={profilePass.image_url}
                  showLoading={true}
                  fit="cover"
                  duration={100}
                  width="80px"
                  height="80px"
                  style={{ borderRadius: 50 }}
                />
              }
              {!profilePass.image_url &&
                <Avatar sx={{ bgcolor: lightBlue[700], width: 80, height: 80 }}>
                  <BadgeOutlinedIcon style={{ fontSize: 48 }} />
                </Avatar>

              }

              <Stack>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{profilePass.first_name}</Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{profilePass.last_name}</Typography>
              </Stack>
            </Stack>


            {profilePass && profilePass.vital_info && profilePass.vital_info.length > 0 &&
              <Stack spacing={0}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Vital info:</Typography>
                <Box
                  sx={{
                    borderRadius: '16px',
                    backgroundColor: grey[200],
                    padding: '8px'
                  }}
                >
                  <ParticipantCardChips
                    chips={profilePass.vital_info}
                    chipColor="primary"
                  />
                </Box>
              </Stack>
            }
            {profilePass && profilePass.allergies && profilePass.allergies.length > 0 &&
              <Stack spacing={0}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Allergies:</Typography>
                <Box
                  sx={{
                    borderRadius: '16px',
                    backgroundColor: grey[200],
                    padding: '8px'
                  }}
                >
                  <ParticipantCardChips
                    chips={profilePass.allergies}
                    chipColor="secondary"
                  />
                </Box>
              </Stack>

            }
          </Stack>
        </Paper>
      </Box>
    </Box>
  )
}