import React, { useState, useEffect } from 'react'
import { Stack, Typography, Box } from '@mui/material';
import AddParticipantDialog from './AddParticipantDialog';
import ParticipantCard from './ParticipantCard';
import CenteredButton from './CenteredButton';
import { GuestsType } from '../../constants';
import { blue } from '@mui/material/colors';
import InfoIcon from '@mui/icons-material/Info';

export default function ParticipantsList({ participantsInitValue, guestsType, handleParticipantsChange }) {
  const [participant, setParticipant] = useState(null)
  const [participants, setParticipants] = useState(participantsInitValue)
  const [openAddParticipant, setOpenAddParticipant] = useState();

  const handleAddParticipant = (participant) => {
    const newParticipants = [...participants, participant]
    setParticipants(newParticipants);
  }

  const handleUpdateParticipant = (participant) => {
    const newParticipants = participants.map(object => {
      if (object.id === participant.id) {
        return { ...participant }
      } else {
        return object
      }
    })

    setParticipants(newParticipants)
  }

  const handleDeleteParticipant = (participant) => {
    const newParticipants = participants.filter(object => object.id !== participant.id)
    setParticipants(newParticipants);
  }

  const handleAddClick = () => {
    setOpenAddParticipant(true)
  }

  const handleClose = () => {
    setParticipant(null)
    setOpenAddParticipant(false)
  }

  useEffect(() => {
    handleParticipantsChange(participants)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants])

  useEffect(() => {
    if (participant) {
      setOpenAddParticipant(true)
    }
  }, [participant])

  const description = (guestsType) => {
    switch (guestsType) {
      case GuestsType.Anyone:
        return "For this event, anyone is allowed."
      case GuestsType.KidsOnly:
        return "For this event, only kid participants are allowed. Please add kid(s) only."
      case GuestsType.AdultsRequired:
        return "Adult participants are required for this event, so please add both adult(s) and kid(s) participant(s) to ensure that everyone can enjoy the fun."
      case GuestsType.AdultsOptional:
        return "Adult participation is optional for this event, so feel free to add participant(s) as desired."
      case GuestsType.AdultsOnly:
        return "For this event, only adults participants are allowed. Please add adult(s) only."
      default:
        return ""
    }
  }

  return (
    <>
      <ParticipantInformation
        text={description(guestsType)}
      />

      <Stack spacing={3}>
        {participants.map((participant) => (
          <ParticipantCard
            key={participant.id}
            participant={participant}
            onClick={() => setParticipant(participant)}
          />
        ))}
      </Stack>

      <CenteredButton
        text="+ Add participant"
        variant='outlined'
        onClick={handleAddClick}
      />

      {
        openAddParticipant &&
        <AddParticipantDialog
          participant={participant}
          guestsType={guestsType}
          addParticipant={handleAddParticipant}
          updateParticipant={handleUpdateParticipant}
          deleteParticipant={handleDeleteParticipant}
          onClose={handleClose}
        />
      }
    </>
  )
}

function ParticipantInformation({text}) {
  return (
    <Box sx={{
      borderRadius: 6,
      backgroundColor: blue[50],
      padding: 2

    }}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <InfoIcon sx={{ color: blue[500] }} />
        <Typography variant="subtitle2" display="block" gutterBottom>{text}</Typography>
      </ Stack>
    </Box>
  )
}