import amplitude from 'amplitude-js'

const API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY

if (API_KEY) {
    amplitude.getInstance().init(API_KEY)
}

const setUserId = (userId) => {
    if (API_KEY) {
        amplitude.getInstance().setUserId(userId)
    }
}

const logClick = (click, screen, properties) => {
    if (API_KEY) {
        const prop = {
            screen_name: screen,
            click_name: click
        }

        amplitude.getInstance().logEvent(
            'click', 
            {...prop, ...properties},
        )
    }
}

export { setUserId, logClick }