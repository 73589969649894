import { httpsCallable } from 'firebase/functions'
import { useState, useEffect, useCallback } from 'react'
import { functions } from '../firebase/firebase.config'

export const useFunction = (_functionName, _params) => {
    const [functionName, setFunctionName] = useState(_functionName);
    const [params, setParams] = useState(_params);

    const [data, setData] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const [error, setError] = useState(null)

    const setFunction = useCallback((functionName, params) => {
        setFunctionName(functionName);
        setParams(params)
    }, [])

    //const  params = useRef(_params).current

    useEffect(() => {

        const fetchData = async () => {
            setIsPending(true)

            try {
                const func = httpsCallable(functions, functionName)
                const result = await func(params)
                const data = result.data

                if (data.error) {
                    throw new Error(data.error)
                }
                
                setIsPending(false)
                setData(data)
                setError(null)
            } catch (error) {
                setIsPending(false)
                setError(error.message)
            }
        }

        if (functionName && params) {
            fetchData()
        }

    }, [functionName, params])

    return { data, isPending, error, setFunction }
}