import React from 'react';
import { Typography, Box, Stack, Grid, Avatar, Paper, ButtonBase } from '@mui/material';
import ParticipantCardChips from './ParticipantCardChips';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import { lightGreen, red } from '@mui/material/colors';

export default function ParticipantCard({ participant, onClick }) {
  return (
    <ButtonBase onClick={onClick}>
      <Box sx={{ width: '100%' }}>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            borderRadius: 6,
            boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.1)',
            borderWidth: 0.5,
            borderColor: '#ccc',
            borderStyle: 'solid'
          }
          }>
          <Grid container spacing={2} >
            <Grid item sx={{ display: 'flex' }}>
              <Box sx={{ width: '100%', py: 2 }}>
                {participant.group === "kid" &&
                  <Avatar sx={{ bgcolor: lightGreen[600] }}>
                    <ChildCareIcon />
                  </Avatar>

                }

                {participant.group === "adult" &&
                  <Avatar sx={{ bgcolor: red[400] }}>
                    <EscalatorWarningIcon />
                  </Avatar>

                }
              </Box>
            </Grid>

            <Grid item xs>
              <Stack spacing={2}>
                <Stack spacing={0} alignItems="flex-start">
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{`${participant.first_name} ${participant.last_name}`}</Typography>
                  <Typography variant="subtitle1" >{`Type: ${capitalizeFirstLetter(participant.group)}`}</Typography>
                </Stack>

                <Stack spacing={1}>
                  {participant && participant.vital_info && participant.vital_info.length > 0 &&
                    <ParticipantCardChips
                      chips={participant.vital_info}
                      title="Vital info:"
                      chipColor="primary"
                    />
                  }
                  {participant && participant.allergies && participant.allergies.length > 0 &&
                    <ParticipantCardChips
                      chips={participant.allergies}
                      title="Allergies:"
                      chipColor="secondary"
                    />
                  }
                </Stack>

              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </ButtonBase >
  )
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}