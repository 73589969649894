import React, { useEffect, useState } from "react"
import { useSearchParams } from 'react-router-dom'
import { useFunction } from '../../../hooks/useFunction'
import { Container, Box, CircularProgress } from '@mui/material'
import CenteredError from '../../../components/CenteredError'
import ProfilePassCard from './ProfilePassCard'

export default function ProfilePass() {
  const [searchParams] = useSearchParams()
  const [isPending, setIsPending] = useState(false)
  const [profilePass, setProfilePass] = useState(null)
  const [error, setError] = useState(null)
  const { data: profilePassData, isPending: profilePassPending, error: profilePassError, setFunction: setGetInvitationFunction } = useFunction()

  useEffect(() => {
    const passId = searchParams.get('id')
    getProfilePass(passId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const getProfilePass = (passId) => {
    if (passId && passId.length > 0) {
      setGetInvitationFunction('profile_pass_by_id_web', { pass_id: passId })
    }
  }

  useEffect(() => {
    setIsPending(profilePassPending)
  }, [profilePassPending])

  useEffect(() => {
    setError(profilePassError)
  }, [profilePassError])

  useEffect(() => {
    setProfilePass(profilePassData)
  }, [profilePassData])

  return (
    <Container maxWidth="xs">
      {isPending &&
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      }

      {error && !isPending &&
        <CenteredError error={error}/>
      }

      {profilePass &&
        <ProfilePassCard profilePass={profilePass}/>
      }
    </Container>
  )
}