import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// init firebase
const app = initializeApp(firebaseConfig)

// init app check
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_RE_CAPTCHA_SITE_KEY),
  isTokenAutoRefreshEnabled: true
});

// init firestore
const db = getFirestore()

// functions
const functions = getFunctions(app)

export { db, functions }