import React, { useEffect, useState, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useFunction } from '../../hooks/useFunction'
import { v4 as uuidv4 } from 'uuid';
import { Image } from 'mui-image';
import { GuestsType } from '../../constants'
import dateFormat from "dateformat";
import DateCard from './DateCard';
import AddToCalendar from './AddToCalendar';
import CenteredError from '../../components/CenteredError'

import { setUserId, logClick } from '../../amplitude/amplitude.config'

// style
import './Invitation.css'
import { Container, Box, CircularProgress, Stack, Avatar } from '@mui/material';
import DateCards from './DateCards';
import EditCard from './EditCard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ParticipantsList from './ParticipantsList';
import CenteredButton from './CenteredButton';
import InformationCard from './InformationCard';
import { deepOrange, deepPurple, yellow } from '@mui/material/colors';
import CongratulationsModal from './CongratulationsModal';

export default function Invitation() {
  const [searchParams] = useSearchParams()
  const [isPending, setIsPending] = useState(false)
  const [error, setError] = useState(null)
  const [invitation, setInvitation] = useState(null)
  const { data: invitationData, isPending: invitationPending, error: invitationError, setFunction: setGetInvitationFunction } = useFunction()
  const { data: updateResult, error: updateInvitationError, setFunction: setUpdateInvitationFunction } = useFunction()

  const [participants, setParticipants] = useState([]);
  const [datesSelection, setDatesSelection] = useState({});
  const [sendButtonActive, setSendButtonActive] = useState(false);
  const [invitationHasUpdated, setInvitationHasUpdated] = useState(false);
  const [openCongratulationsModal, setOpenCongratulationsModal] = useState(false);


  const [isEditParticipants, setIsEditParticipants] = useState(false)
  const [isEditSelectDates, setIsEditSelectDates] = useState(false)
  const [isParticipantsCreated, setIsParticipantsCreated] = useState(false)
  const [isDateSelected, setIsDateSelected] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null)

  const updateInvitation = useCallback((invitation, participants, datesSelection) => {
    const votes = Object.entries(datesSelection).reduce((result, [key, value]) => {
      result[key] = {
        id: value.id,
        event_date_id: value.event_date_id,
        result: value.result
      }

      return result
    }, {})

    const guests = participants.map((participant) => {
      return {
        id: participant.id,
        group: participant.group,
        first_name: participant.first_name,
        last_name: participant.last_name,
        vital_info: participant.vital_info,
        allergies: participant.allergies,
        image_url: "",
        is_selectable: true,
        is_selected: true,
        add_allergies: participant.allergies.length > 0,
        add_vital_info: participant.vital_info.length > 0
      }
    })

    setUpdateInvitationFunction('invitation_update_web', {
      invitation: invitation,
      votes: votes,
      guests: guests
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const invitationId = searchParams.get('id')
    getInvitation(invitationId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const getInvitation = (invitationId) => {
    if (invitationId && invitationId.length > 0) {
      setGetInvitationFunction('invitation_by_id_web', { invitation_id: invitationId })
    }
  }

  useEffect(() => {
    setIsPending(invitationPending)
  }, [invitationPending])

  useEffect(() => {
    setError(invitationError)
  }, [invitationError])

  useEffect(() => {
    setError(updateInvitationError)
  }, [updateInvitationError])

  useEffect(() => {
    setInvitation(invitationData)
  }, [invitationData])

  useEffect(() => {
    if (invitation) {
      setUserId(invitation.invitation_id)

      if (invitation.attendee_info) {
        const votes = invitation.attendee_info.votes || {}
        const participants = invitation.attendee_info.guests

        const datesSelectionResult = Object.entries(invitation.dates).reduce((accumulator, [key, value]) => {
          const vote = votes[key] || {}
          accumulator[key] = {
            id: vote.id ?? uuidv4(),
            event_date_id: vote.event_date_id ?? value.id,
            result: vote.result ?? '',
            start: value.start,
            duration: value.duration
          }
          return accumulator
        }, {})

        setParticipants(participants)

        setDatesSelection(datesSelectionResult)
      } else {
        const result = Object.entries(invitation.dates).reduce((accumulator, [key, value]) => {
          accumulator[key] = {
            id: uuidv4(),
            event_date_id: value.id,
            result: '',
            start: value.start,
            duration: value.duration
          }
          return accumulator
        }, {})

        setDatesSelection(result)
      }

      if (invitation.selected_date) {
        const start = new Date(Date.parse(invitation.selected_date.start))
        const end = new Date(Date.parse(invitation.selected_date.start) + invitation.selected_date.duration * 1000)
        const fullDate = dateFormat(start, "ddd, mmm dS")
        const timeRange = `${dateFormat(start, "h:MM")}-${dateFormat(end, "h:MM TT")}`
        setSelectedDate(`${fullDate} ${timeRange}`)
      }

    }
  }, [invitation])

  useEffect(() => {
    if (updateResult) {
      setInvitationHasUpdated(true)

      logClick(
        invitation.attendee_info ? "Invitation updated" : "Invitation submited",
        'Invitation',
        {
          user_id: invitation.invitation_id,
          invitation_id: invitation.invitation_id
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateResult])

  useEffect(() => {
    let isParticipants = false
    let isDates = false

    if (participants && participants.length > 0) {
      isParticipants = checkParticipants(participants, invitation.guests_type)
      setIsParticipantsCreated(isParticipants)
    } else {
      setIsParticipantsCreated(false)
    }

    const dates = Object.values(datesSelection)
    if (dates && dates.length > 0) {
      const allSelected = !(dates.filter(date => date.result === '').length > 0)
      if (allSelected) {
        isDates = true
        setIsDateSelected(true)
      } else {
        setIsDateSelected(false)
      }
    } else {
      setIsDateSelected(false)
    }

    setSendButtonActive((isParticipants && isDates))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants, datesSelection])

  const handleDatesSelectionChange = (value, id) => {
    setDatesSelection((prevDates) => {
      prevDates[id].result = value
      return { ...prevDates }
    })

    setInvitationHasUpdated(false)
  };

  const handleParticipantsChange = (participants) => {
    setParticipants((prevParticipants) => {
      return [...participants]
    })

    setInvitationHasUpdated(false)
  }

  const handleSendButton = () => {
    updateInvitation(invitation, participants, datesSelection)
    setOpenCongratulationsModal(true)
  };

  const handleCloseCongratulationsModal = () => {
    setOpenCongratulationsModal(false);
    const invitationId = searchParams.get('id')
    getInvitation(invitationId)
  };

  const checkParticipants = (participants, guestsType) => {
    const adults = participants.filter((p) => p.group === 'adult').length > 0
    const kids = participants.filter((p) => p.group === 'kid').length > 0

    switch (guestsType) {
      case GuestsType.Anyone:
        return adults || kids
      case GuestsType.KidsOnly:
        return !adults && kids
      case GuestsType.AdultsRequired:
        return adults && kids
      case GuestsType.AdultsOptional:
        return kids
      case GuestsType.AdultsOnly:
        return adults && !kids
      default:
        return false
    }
  }

  const formatAddress = (location) => {
    if (location?.name) {
      return location.name
    } else if (location?.address) {
      const address = location?.address
      return `${address.street}, ${address.city}, ${address.state}, ${address.postal_code}`
    } else {
      return undefined
    }
  }

  return (
    <Container maxWidth="xs">
      {isPending &&
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      }

      {error && !isPending &&
        <CenteredError error={error}/>
      }

      {invitation &&
        <Stack spacing={2} alignItems="center" sx={{ py: 2 }}>
          <Image
            src={invitation.invitation_screenshot.image_url}
            showLoading={true}
            fit="scale-down"
            duration={100}
            width="100%"
          />

          <EditParticipants
            isEditParticipants={isEditParticipants}
            participants={participants}
            guestsType={invitation.guests_type}
            handleParticipantsChange={handleParticipantsChange}
            handleSave={() => setIsEditParticipants(false)}
          />

          <EditDates
            isEditSelectDates={isEditSelectDates}
            datesSelection={datesSelection}
            handleDatesSelectionChange={handleDatesSelectionChange}
            handleSave={() => setIsEditSelectDates(false)}
          />

          <InvitationSelection
            invitation={invitation}
            isEditParticipants={isEditParticipants}
            isEditSelectDates={isEditSelectDates}
            isParticipantsCreated={isParticipantsCreated}
            isDateSelected={isDateSelected}
            invitationHasUpdated={invitationHasUpdated}
            formatedAddress={formatAddress(invitation.location)}
            selectedDate={selectedDate}
            sendButtonActive={sendButtonActive}
            datesSelection={datesSelection}
            handleDatesSelectionChange={handleDatesSelectionChange}
            handleEditSelectDates={(isEdit) => setIsEditSelectDates(isEdit)}
            handleEditsetParticipants={(isEdit) => setIsEditParticipants(isEdit)}
            handleSendButton={handleSendButton}
          />

        </Stack>
      }

      {openCongratulationsModal &&
        <CongratulationsModal
          open={openCongratulationsModal}
          complited={invitationHasUpdated}
          onClose={handleCloseCongratulationsModal}
        />
      }
    </Container>
  )
}

function InvitationSelection({
  invitation,
  isEditParticipants,
  isEditSelectDates,
  isParticipantsCreated,
  isDateSelected,
  invitationHasUpdated,
  formatedAddress,
  selectedDate,
  sendButtonActive,
  datesSelection,
  handleDatesSelectionChange,
  handleEditSelectDates,
  handleEditsetParticipants,
  handleSendButton
}) {
  const dates = { ...datesSelection }
  const datesCount = Object.keys(dates).length
  return (
    <>
      {!isEditParticipants && !isEditSelectDates &&
        <Box sx={{ width: '100%', paddingBottom: 4 }}>
          <Stack spacing={3} >
            {invitation.selected_date &&
              <AddToCalendar invitation={invitation} />
            }

            <InformationCard
              title="Event Information"
              subtitle={invitation.event_status_message}
              address={formatedAddress}
              selectedDate={selectedDate}
              renderAvatarComponent={() => <Avatar sx={{ bgcolor: yellow[500] }}><PeopleAltIcon /></Avatar>}
            />

            <EditCard
              title="Participants"
              subtitle="Select attending participants"
              isComplited={isParticipantsCreated}
              renderAvatarComponent={() => <Avatar sx={{ bgcolor: deepOrange[500] }}><PeopleAltIcon /></Avatar>}
              onClick={handleEditsetParticipants}
            />

            {datesCount > 1 &&
              <EditCard
                title="Date Selection"
                subtitle="Select dates"
                isComplited={isDateSelected}
                renderAvatarComponent={() => <Avatar sx={{ bgcolor: deepPurple[500] }}><CalendarMonthIcon /></Avatar>}
                onClick={handleEditSelectDates}
              />
            }

            {datesCount === 1 &&
              <SingleDateSelection
                datesSelection={dates}
                handleDatesSelectionChange={handleDatesSelectionChange}
              />
            }

            {!invitationHasUpdated &&
              < CenteredButton
                text={invitation.attendee_info ? "Update" : "Submit"}
                variant={invitation.attendee_info ? "outlined" : "contained"}
                disabled={!sendButtonActive}
                onClick={handleSendButton}
              />
            }
          </Stack>
        </Box>
      }
    </>
  )
}

function SingleDateSelection({
  datesSelection,
  handleDatesSelectionChange
}) {
  const date = Object.values(datesSelection)[0]
  return (
    <>
      {date &&
        <DateCard
          key={date.event_date_id}
          dateObject={{ date: date.start, duration: date.duration }}
          selected={date.result}
          isMaybe={false}
          handleButtonSelection={(event, value) => {
            handleDatesSelectionChange(value, date.event_date_id)
          }}
        />
      }
    </>
  )
}

function EditDates({
  isEditSelectDates,
  datesSelection,
  handleDatesSelectionChange,
  handleSave
}) {
  return (
    <>
      {isEditSelectDates &&
        <Box sx={{ width: '100%', paddingBottom: 3 }}>
          <Stack spacing={2}>
            <DateCards
              datesSelection={{ ...datesSelection }}
              handleDatesSelectionChange={handleDatesSelectionChange}
            />

            <CenteredButton
              text="Save"
              onClick={handleSave}
            />

          </Stack>
        </Box>
      }
    </>
  )
}

function EditParticipants({
  isEditParticipants,
  participants,
  guestsType,
  handleParticipantsChange,
  handleSave
}) {
  return (
    <>
      {isEditParticipants &&
        <Box sx={{ width: '100%', paddingBottom: 3 }}>
          <Stack spacing={3}>
            <ParticipantsList
              participantsInitValue={[...participants]}
              guestsType={guestsType}
              handleParticipantsChange={handleParticipantsChange}
            />

            <CenteredButton
              text="Save"
              onClick={handleSave}
            />

          </Stack>
        </Box>
      }
    </>
  )
}