import { Stack } from "@mui/material";
import dateFormat from "dateformat";
import { AddToCalendarButton } from 'add-to-calendar-button-react';


export default function AddToCalendar({invitation}) {
  const calendars = ['Apple','Google']

  const formatAddress = (location) => {
    if (location?.name) {
      return location.name
    } else if (location?.address) {
      const address = location?.address
      return `${address.street}, ${address.city}, ${address.state}, ${address.postal_code}`
    } else {
      return undefined
    }
  }

  const startDate = (date) => {
    const start = new Date(Date.parse(date.start))
    return dateFormat(start, "yyyy-mm-dd")
  }

  const endDate = (date) => {
    const end = new Date(Date.parse(date.start) + date.duration * 1000)
    return dateFormat(end, "yyyy-mm-dd")
  }

  const startTime = (date) => {
    const start = new Date(Date.parse(date.start))
    return dateFormat(start, "HH:MM")
  }

  const endTime = (date) => {
    const end = new Date(Date.parse(date.start) + date.duration * 1000)
    return dateFormat(end, "HH:MM")
  }

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const organizer = invitation.host_name && invitation.host_email ? `${invitation.host_name}|${invitation.host_email}` : undefined

  return (
    <>
      <Stack
        direction="column"
        alignItems="center"
        spacing={2}
      >
        <AddToCalendarButton
          name={invitation.title}
          description={invitation.dynamic_link}
          options={calendars}
          location={formatAddress(invitation.location)}
          startDate={startDate(invitation.selected_date)}
          endDate={endDate(invitation.selected_date)}
          startTime={startTime(invitation.selected_date)}
          endTime={endTime(invitation.selected_date)}
          organizer={organizer}
          timeZone={timeZone}
          listStyle="dropdown"
          buttonStyle="round"
          iCalFileName="Huddle-Event"
          hideCheckmark={true}
          buttonsList={true}
          size="5"
          hideBranding={true}
        ></AddToCalendarButton>
      </Stack>
    </>
  );
}