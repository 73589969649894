import React from 'react'
import { Button, Stack } from '@mui/material';

export default function CenteredButton({ text, variant = "contained", color, disabled = false, onClick, sx }) {
    return (
        <>
            <Stack
                direction="column"
                alignItems="center"
                spacing={2}
            >
                <Button
                    color={color}
                    variant={variant}
                    size="large"
                    disabled={disabled}
                    onClick={onClick}
                    sx={sx}
                >
                    {text}
                </Button>
            </Stack>
        </>
    )
}
