import React from 'react'
import { Chip, Stack, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { VitalInfoAllergiesChipsTheme } from '../../constants'

export default function ParticipantCardChips({ chips, title, chipColor }) {
    return (
        <ThemeProvider theme={VitalInfoAllergiesChipsTheme}>
            <Stack
                spacing={{ xs: 1, sm: 1 }}
                direction="row"
                justifyContent="flex-start"
                alignItems="baseline"
                useFlexGap
                flexWrap="wrap"
            >
                <Typography variant="body1" m={0} p={0}>{title}</Typography>

                {Object.values(chips).length > 0 && Object.values(chips).map((chip, index) => (
                    <Chip
                        key={index}
                        label={chip}
                        color={chipColor}
                        variant="solid"
                        size="small"
                    />
                ))}
            </Stack>
        </ThemeProvider>
    )
}