import React, { useState } from 'react';
import { Button, TextField, Typography, Chip, Stack } from '@mui/material';
import { VitalInfoAllergiesChipsTheme } from '../../constants'
import { ThemeProvider } from '@mui/material/styles';

export default function AddInfo({ title, example, chipColor, chips, onChipsChange }) {
  const [textFieldValue, setTextFieldValue] = useState('');

  const handleAddChip = () => {
    if (textFieldValue && textFieldValue.length > 0 && !chips.includes(textFieldValue)) {
      const updatedChips = [...chips, textFieldValue];
      onChipsChange(updatedChips);
      setTextFieldValue('')
    }
  };

  const handleChipDelete = (label) => {
    const updatedChips = chips.filter(chip => chip !== label);
    onChipsChange(updatedChips);
  };

  return (
    <div>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Stack
            spacing={{ xs: 1, sm: 1 }}
            direction="row"
            justifyContent="flex-start"
            alignItems="baseline"
            useFlexGap
            flexWrap="wrap"
          >
            <Typography variant="body1" m={0} p={0}>{title}</Typography>

            <Stack
              spacing={{ xs: 0, sm: 0 }}
              direction="row"
              justifyContent="flex-start"
              alignItems="baseline"
              useFlexGap
              flexWrap="wrap"
            >
              <TextField
                value={textFieldValue}
                label={example}
                variant="standard"
                margin="none"
                size="small"
                // helperText="Some important text"
                sx={{ mr: 2 }}
                //placeholder="SX"
                onChange={(event) => setTextFieldValue(event.target.value)}
              />

              <Button variant="outlined" onClick={handleAddChip}>Add</Button>
            </Stack>


          </Stack>
        </Stack>


        <Stack spacing={2} sx={{ width: '100%' }}>
          <Stack
            spacing={{ xs: 1, sm: 1 }}
            direction="row"
            justifyContent="flex-start"
            alignItems="baseline"
            useFlexGap
            flexWrap="wrap"
          >
            {chips.length > 0 && Object.values(chips).map((chip, index) => (
              <ThemeProvider theme={VitalInfoAllergiesChipsTheme}>
                <Chip
                  key={index}
                  label={chip}
                  color={chipColor}
                  variant="solid"
                  onDelete={() => handleChipDelete(chip)}
                />
              </ThemeProvider>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
}