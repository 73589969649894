import React from 'react'
import { Stack, Grid, Typography, Box } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import InfoIcon from '@mui/icons-material/Info';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { blue } from '@mui/material/colors';
import { yellow } from '@mui/material/colors';

export default function InformationCard({ title, subtitle, address, selectedDate, renderAvatarComponent }) {
  return (
    <>
    {(subtitle || address) &&
      <Box sx={{
        borderRadius: 6,
        backgroundColor: blue[50],
        padding: 2
  
      }}>
        <Grid container spacing={2} >
          <Grid item sx={{ display: 'flex' }}>
            <Stack direction="column" alignItems="flex-start" spacing={1}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <TipsAndUpdatesIcon fontSize="large" sx={{ color: yellow[600], fontSize: 40 }} />
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{title}</Typography>
              </ Stack>
              <Stack direction="column" spacing={1} sx={{ pl: 2 }}>
  
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <LocationOnIcon sx={{ color: blue[500] }} />
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    {address}
                  </Typography>
                </ Stack>
  
                {selectedDate &&
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <EventAvailableIcon sx={{ color: blue[500] }} />
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>{selectedDate}</Typography>
                  </ Stack>
  
                }
  
                {subtitle &&
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <InfoIcon sx={{ color: blue[500] }} />
                    <Typography variant="subtitle2" display="block" gutterBottom>{subtitle}</Typography>
                  </ Stack>
                }
              </Stack>
            </ Stack>
          </Grid>
        </Grid>
      </Box>
    }
    </>
  )
}