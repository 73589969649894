import React from 'react'

// styles
import './Home.css'

export default function Home() {
  return (
    <div className='content'>
    </div>
  )
}