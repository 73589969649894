import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Typography, Container, Button, Grid, Box } from "@mui/material";
import HuddleAppLogo from "../../images/huddle_app_logo.png"
import { DownloadFromStore } from "../../constants";
import CloseIcon from '@mui/icons-material/Close';

const BannerAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  borderBottom: `1px solid ${theme.palette.divider}`,
  position: "sticky",
  top: 0,
  zIndex: theme.zIndex.drawer + 1,
}));


export default function Banner() {
  const [isClose, setIsClose] = useState(false)
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
  const isAndroid = /Android/i.test(navigator.userAgent) || navigator.userAgent.toLowerCase().indexOf('android') > -1

  const handleInstall = () => {
    if (isIOS) {
      window.location.href = DownloadFromStore.iosURL
    }

    if (isAndroid) {
      window.location.href = DownloadFromStore.androidURL
    }
  };

  const handleClose = () => {
    setIsClose(true)
  }

  return (
    <>
      {(isIOS || isAndroid) && !isClose &&
        <BannerAppBar>
          <Container maxWidth="xs" sx={{ my: 2, pl: 1, pr: 2 }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
              <CloseIcon sx={{ width: '16px', height: '16px', }}  onClick={handleClose}/>
              </Grid>
              <Grid alignItems="center" justifyContent="center" item>
                <Box
                  component="img"
                  src={HuddleAppLogo}
                  sx={{ borderRadius: '12px', width: '64px', height: '64px', }}
                  alt="Huddle - Playdate Planner"
                />
              
              </Grid>
              <Grid item xs >
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Huddle - Playdate Planner</Typography>
                <Typography variant="caption" display="block" gutterBottom>Schedule, Prepare, Play</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  disableElevation
                  onClick={handleInstall}
                  color="warning">
                  Install
                </Button>
              </Grid>
            </Grid>
          </Container>
        </BannerAppBar>
      }
    </>
  );
}