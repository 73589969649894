import React from 'react'
import { Box, Typography } from '@mui/material';

export default function CenteredError({ error }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Typography variant="body1" m={0} p={0}>{error}</Typography>
    </Box>
  )
}