import React, { useState } from 'react'
import { Grid, Stack, Dialog, DialogTitle, DialogContent, TextField, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import AddInfo from './AddInfo';
import CenteredButton from './CenteredButton';
import { GuestsType } from '../../constants'

export default function AddParticipantDialog({ participant, guestsType, addParticipant, updateParticipant, deleteParticipant, onClose }) {
  const getGroupFromGuestsType = (guestsType) => {
    switch (guestsType) {
      case GuestsType.AdultsOnly:
        return "adult"
      default:
        return "kid"
    }
  }

  const [open, setOpen] = useState(true);
  const [firstName, setFirstName] = useState(participant?.first_name ?? "")
  const [lastName, setLastName] = useState(participant?.last_name ?? "")
  const [group, setGroup] = useState(participant?.group ?? getGroupFromGuestsType(guestsType));
  const [vitalInfoChips, setVitalInfoChips] = useState(participant?.vital_info ?? []);
  const [allergyChips, setAlergyChips] = useState(participant?.allergies ?? []);

  const handleAdd = () => {
    const newParticipant = {
      id: uuidv4(),
      first_name: firstName,
      last_name: lastName,
      group: group,
      allergies: allergyChips,
      vital_info: vitalInfoChips
    };

    addParticipant(newParticipant)
    handleClose();
  };

  const handleUpdate = () => {
    if (participant) {
      updateParticipant(
        {
          ...participant,
          first_name: firstName,
          last_name: lastName,
          group: group,
          allergies: allergyChips,
          vital_info: vitalInfoChips
        }
      )
    }

    handleClose();
  };

  const handleDelete = () => {
    if (participant) {
      deleteParticipant(participant)
    }
    handleClose();
  };

  const handleGroupChange = (event) => {
    setGroup(event.target.value);
  };

  const handleVitalInfoChipsChange = (newChips) => {
    setVitalInfoChips(newChips);
  };

  const handleAlergyChipsChange = (newChips) => {
    setAlergyChips(newChips);
  };

  const handleClose = () => {
    onClose()
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Participant</DialogTitle>
      <DialogContent>
        <TextField label="First Name" fullWidth value={firstName} onChange={(e) => setFirstName(e.target.value)} sx={{ mb: 2, mt: 2 }} />
        <TextField label="Last Name" fullWidth value={lastName} onChange={(e) => setLastName(e.target.value)} sx={{ mb: 2 }} />

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="profile-type-select-label">Profile type</InputLabel>
          <Select
            id="profile-type-simple-select"
            value={group}
            label="Profile type"
            onChange={handleGroupChange}
          >
            {guestsType && (
              (() => {
                switch (guestsType) {
                  case GuestsType.KidsOnly:
                    return <MenuItem value={"kid"}>Kid</MenuItem>
                  case GuestsType.AdultsOnly:
                    return <MenuItem value={"adult"}>Adult</MenuItem>
                  default:
                    return [
                      <MenuItem key="kid" value="kid">Kid</MenuItem>,
                      <MenuItem key="adult" value="adult">Adult</MenuItem>
                    ];
                }
              })()
            )}
          </Select>
        </FormControl>

        <Stack spacing={2}>
          <AddInfo
            title="Vital info:"
            example="Example: Asthma"
            chipColor="primary"
            chips={vitalInfoChips}
            onChipsChange={handleVitalInfoChipsChange}
          />

          <AddInfo
            title="Allergy:"
            example="Example: Peanuts"
            chipColor="secondary"
            chips={allergyChips}
            onChipsChange={handleAlergyChipsChange}
          />
        </Stack>

        {!participant &&
          <CenteredButton
            text="Save"
            disabled={!(firstName && firstName.length > 0)}
            onClick={handleAdd}
            sx={{ my: 2 }}
          />
        }

        {participant &&
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ my: 2 }}
          >
            <Grid item>
              <CenteredButton
                text="Delete"
                color="error"
                onClick={handleDelete}
              />
            </Grid>
            <Grid item>
              <CenteredButton
                text="Update"
                onClick={handleUpdate}
              />
            </Grid>
          </Grid>

        }

      </DialogContent>

    </Dialog>
  )
}
