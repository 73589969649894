import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Home from './pages/home/Home';
import Invitation from './pages/invitation/Invitation';
import ProfilePass from './pages/profile/pass/ProfilePass';

// style
import './App.css';
import Banner from './pages/invitation/Banner';

function App() {
  return (
    <div className="App">
      <Banner />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/invitation' element={<Invitation />} />
          <Route path='/profile/pass' element={<ProfilePass />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;